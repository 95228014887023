var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/v2/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"dev.ff7639d6cc28296286b894d4bb5011da49a4586f"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/v2";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {isFeatureFlagEnabled} from '@/utils/featureFlags'
import {init, replayIntegration} from '@sentry/nextjs'

init({
  dsn: 'https://b7f19c9e1e6b48e6a5671875e7915020@sentry.tofisanext.com/16',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
  replaysOnErrorSampleRate: 0,
  replaysSessionSampleRate: 0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  enabled: isFeatureFlagEnabled('SENTRY'),

  integrations: [
    replayIntegration({
      maskAllInputs: true, // Mask sensitive inputs (optional)
      blockAllMedia: true, // Block all media elements (optional)
      sessionSampleRate: 1.0, // Sample rate for session replays (0.0 - 1.0)
      errorSampleRate: 1.0, // Sample rate when an error occurs (0.0 - 1.0)
    }),
  ],

  beforeSend(event, hint) {
    const error = hint?.originalException

    if (
      error instanceof Error &&
      error.name === 'CanceledError' &&
      error.message.startsWith('canceled')
    ) {
      return null // Ignore the event
    }

    return event // Send other events to Sentry
  },
})
